@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Gotham";
  font-style: "normal";
  font-weight: 400;
  font-display: swap;
  src: url(/public/fonts/Gotham-Book.ttf) format("truetype");
}

@font-face {
  font-family: "Gotham";
  font-style: "normal";
  font-weight: 500;
  font-display: swap;
  src: url(/public/fonts/Gotham-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Gotham";
  font-style: "normal";
  font-weight: 700;
  font-display: swap;
  src: url(/public/fonts/Gotham-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Gotham";
  font-style: "normal";
  font-weight: 900;
  font-display: swap;
  src: url(/public/fonts/Gotham-Black.ttf) format("truetype");
}

@layer utilities {
  .scrollbar-gutter-stable {
    scrollbar-gutter: stable;
  }
}

/* Style for WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px; /* Height of the scrollbar for horizontal scrolling */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track (the area where the scrollbar moves) */
  border-radius: 10px; /* Rounded corners for the track */
}

::-webkit-scrollbar-thumb {
  background: #a3a3a3; /* Color of the draggable part of the scrollbar */
  border-radius: 10px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #737373; /* Color of the thumb on hover */
}

/* Optional: Style for Edge and IE */
::-ms-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px; /* Height of the scrollbar for horizontal scrolling */
}

::-ms-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
  border-radius: 10px; /* Rounded corners for the track */
}

::-ms-scrollbar-thumb {
  background: #a3a3a3; /* Color of the thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

::-ms-scrollbar-thumb:hover {
  background: #737373; /* Color of the thumb on hover */
}

::-webkit-scrollbar-button {
  display: none;
}
